import { nav } from '@/app/src/shared/config/navigation'

export const linkContacts = nav.find(({ id }) => id === 20)

export const linkBlog = nav.find(({ id }) => id === 25)
export const linkPortfolio = nav.find(({ id }) => id === 15)
export const linkAbout = nav.find(({ id }) => id === 1)
export const linkServices = nav.find(({ id }) => id === 5)
export const linkIndustries = nav.find(({ id }) => id === 10)

export const menuPart = [linkAbout, linkServices, linkIndustries]

export const copyright = '© Dev.family OÜ. All rights reserved'
export const documents = [
  {
    name: 'Privacy Policy',
    link: 'https://docs.google.com/document/d/1FDUdOrdaOsO7fcQAFK2wWFoXP3T8XRD-AK8kc90avyA/edit',
  },
  {
    name: 'Terms and conditions',
    link: 'https://docs.google.com/document/d/1hspX7iv8dCwzkLxY51jVMth02L1sxoKiJkiPV-dzV-U/edit',
  },
]
